.ui.color1 {
  background-color: #11426e;
  color: #fff;
}
.ui.color1:hover {
  background-color: #244460;
  color: #fff;
}
.ui.color1:focus {
  background-color: #244460;
  color: #fff;
}
.ui.color1:active {
  background-color: #244460;
  color: #fff;
}
.ui.inverted.color1.menu,
.ui.inverted.menu .color1.active.item {
  background-color: #11426e;
  color: #fff;
}
